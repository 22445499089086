<template>
  <div id="controlchart">
    <b-card>
      <b-card-title
        class="d-flex justify-content-center"
        style="
          font-weight: 500;
          font-size: 20px;
          font-family: Helvetica;
          color: black;
        "
      >
        {{ $t(data.title.text) }}</b-card-title
      >
      <apexchart
        ref="realtimeChart"
        type="rangeBar"
        height="450"
        :options="chartOptions"
        :series="data.series"
      ></apexchart>
    </b-card>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { BCard } from "bootstrap-vue";

import { toRefs, ref, watch, computed } from "@vue/composition-api";
import { numberFormat } from "highcharts";
import i18n from "@/libs/i18n";
export default {
  components: {
    apexchart: VueApexCharts,
    BCard,
  },
  props: {
    keyVal: Number,
    data: Object,
  },
  setup(props) {
    const locale = computed(() => i18n.locale);
    const { data } = toRefs(props);
    const realtimeChart = ref();
    const chartOptions = ref({
      zoom: {
        enabled: true,
        type: "x",
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: "#90CAF9",
            opacity: 0.4,
          },
          stroke: {
            color: "#0D47A1",
            opacity: 0.4,
            width: 1,
          },
        },
      },
      dataLabels: { enabled: false },
      series: [],
      colors: [
        "#a352cc",
        "#37872d",
        "#fade2a",
        "#fb7821",
        "#c4162a",
        "#8ab8ff",
        "#795548",
        "#E91E63",
        "#03A9F4",
        "#8BC34A",
        "#FFC107",
        "#673AB7",
        "#9E9E9E",
        "#FF5722",
        "#00BCD4",
        "#CDDC39",
        "#FF9800",
        "#3F51B5",
        "#607D8B",
        "#C62828",
        "#1E88E5",
        "#43A047",
        "#FDD835",
        "#AB47BC",
        "#8D6E63",
        "#EC407A",
        "#29B6F6",
        "#9CCC65",
        "#FFCA28",
        "#7E57C2",
        "#BDBDBD",
      ],
      chart: {
        height: 450,
        type: "rangeBar",
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "50%",
          rangeBarGroupRows: true,
        },
      },
      animations: {
        enabled: false,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: false,
          speed: 350,
        },
      },
      xaxis: {
        min: new Date().getTime() + 60000 * 60 * 2,
        type: "datetime",
      },
      stroke: {
        width: 1,
      },
      fill: {
        type: "solid",
        opacity: 1,
      },
      legend: {
        show: true,
      },
      title: { text: "" },
      tooltip: {
        custom: function (opts) {
          let startDate = new Date(opts.y1);
          let endDate = new Date(opts.y2);
          const fromYear = new Intl.DateTimeFormat("tr-TR", {
            timeZone: "UTC",
            hour: "numeric",
            minute: "numeric",
          }).format(startDate);

          const toYear = new Intl.DateTimeFormat("tr-TR", {
            timeZone: "UTC",
            hour: "numeric",
            minute: "numeric",
          }).format(endDate);

          const w = opts.ctx.w;
          const value =
            w.config.series[opts.seriesIndex].data[opts.dataPointIndex].val;
          let yVal =
            w.config.series[opts.seriesIndex].data[opts.dataPointIndex].x;
          let seriesName = w.config.series[opts.seriesIndex].name
            ? w.config.series[opts.seriesIndex].name
            : "";
          const color = w.globals.colors[opts.seriesIndex];
          return (
            '<div class="apexcharts-tooltip-rangebar">' +
            '<div> <span class="series-name" style="color: ' +
            color +
            '">' +
            (value ? value : "") +
            "</span></div>" +
            '<span class="series-name">' +
            "Name: " +
            yVal +
            " </span>" +
            '<span class="value start-value">' +
            fromYear +
            '</span> <span class="separator">-</span> <span class="value end-value">' +
            toYear +
            "</span></div>" +
            "</div>"
          );
        },
      },
    });
    const series = ref(data.value.series);
    watch(
      data,
      () => {
        realtimeChart.value.updateSeries(data.value.series, false);
      },
      { deep: true }
    );

    return {
      data,
      realtimeChart,
      series,
      chartOptions,
    };
  },
};
</script>
<style></style>
